import { decode as jwtDecode } from 'jsonwebtoken'

import { useMemo, useState, useEffect, useContext, createContext } from 'react'

import { useCookies } from 'react-cookie'

const SessionContext = createContext()

export const useSession = (options) => {
    const value = useContext(SessionContext)

    const { required, onAuthenticated, onUnauthenticated } = options ?? {}

    useEffect(() => {
        if (required === true && !value.isAuthenticated && onUnauthenticated) {
            onUnauthenticated()
        } else if (value?.isAuthenticated && onAuthenticated) {
            onAuthenticated()
        }
    }, [required, value.isAuthenticated, onAuthenticated, onUnauthenticated])

    return value
}

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const [nextURL, setNextURL] = useState()

    const [authToken, setAuthToken, removeAuthToken] = useCookies([
        'accessToken',
        'refreshToken',
        'organizationId',
        'projectId',
        'logo',
        'title',
        'isMenuOpen',
    ])

    let userScopes = []
    if (authToken.accessToken) {
        ;({ scopes: userScopes } = jwtDecode(authToken.accessToken))
    }
    const [scopes, setScopes] = useState(userScopes)

    const value = useMemo(
        () => ({
            user,
            setUser,
            scopes,
            setScopes,
            nextURL,
            authToken,
            setNextURL,
            setAuthToken,
            removeAuthToken,
            isAuthenticated: !!authToken.accessToken,
        }),
        [
            user,
            authToken,
            nextURL,
            removeAuthToken,
            setAuthToken,
            scopes,
            setUser,
        ]
    )

    useEffect(() => {
        if (authToken?.accessToken) {
            const { scopes: newScopes } = jwtDecode(authToken.accessToken)

            setScopes(newScopes)

            const getUser = async () => {
                const getUserUrl = `${process.env.NEXT_PUBLIC_API_URL}/user/me`

                const fetchOptions = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken.accessToken}`,
                    },
                }

                const response = await fetch(getUserUrl, fetchOptions)

                if (response.ok) {
                    const data = await response.json()
                    setUser(data.data)
                } else {
                    if (response.status === 401) {
                        removeAuthToken('loggedInUserId', {
                            path: '/',
                            sameSite: true,
                            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        })
                        removeAuthToken('accessToken', {
                            path: '/',
                            sameSite: true,
                            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                        })
                    }
                }
            }
            getUser()
        } else {
            setUser(null)
            setScopes([])
        }
    }, [authToken, removeAuthToken])

    const handleUnauthenticated = (event) => {
        if (event.deleted.filter((a) => a.name === 'accessToken').length) {
            removeAuthToken('accessToken', {
                path: '/',
            })
        }
    }

    useEffect(() => {
        try {
            window.cookieStore.addEventListener('change', handleUnauthenticated)
            return () => {
                window.cookieStore.removeEventListener(
                    'change',
                    handleUnauthenticated
                )
            }
        } catch (e) {
            // eslint-disable-next-line
            console.log(e, 'addEventListener')
        }
    })

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    )
}
