import { useRouter } from 'next/router'
import { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import styles from '@/styles/Select.module.css'

import Image from 'next/image'

import axios from '@/helpers/axios'
import { useSession } from '@/contexts/Session'

const OrganizationList = ({ isSidebarOpen }) => {
    const { push, asPath, pathname } = useRouter()
    const { authToken, setAuthToken, scopes } = useSession()

    const [options, setOptions] = useState([])
    const [currentIndex, setCurrentIndex] = useState(null)

    const [showDropdown, setShowDropdown] = useState(false)

    const dropdownRef = useRef(null)

    const selectedOption = useMemo(() => {
        return options.find(
            (element) => element?.id == authToken?.organizationId
        )
    }, [options, authToken?.organizationId])

    /* select project */
    const selectProject = useCallback(
        ({ index, logo, title, organizationId, projectId, from = null }) => {
            setCurrentIndex(index)
            setAuthToken('organizationId', organizationId, {
                path: '/',
                sameSite: true,
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            })
            setAuthToken('logo', logo, {
                path: '/',
                sameSite: true,
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            })
            setAuthToken('projectId', projectId, {
                path: '/',
                sameSite: true,
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            })
            setAuthToken('title', title, {
                path: '/',
                sameSite: true,
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            })
            if (!from) {
                setShowDropdown((prevState) => !prevState)
            }
            if (pathname === '/analyze' || pathname === '/eco-pilot') {
                push(pathname)
            } else if (
                pathname === '/models/[id]' &&
                asPath !== '/models/create'
            ) {
                push(pathname.replace('/[id]', ''))
            } else if (
                [
                    '/site',
                    '/site/intervals-baseload',
                    '/site/utility-bills',
                    '/site/demand-forecast',
                ]?.includes(pathname)
            ) {
                push(pathname)
            } else {
                push(asPath)
            }
        },
        [setAuthToken, push, asPath, setShowDropdown, pathname]
    )

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get('/organization/projects')

            if (data?.data?.length) {
                if (!authToken?.organizationId) {
                    const organization = data?.data[0]
                    selectProject({
                        index: 0,
                        organizationId: organization?.id,
                        logo: organization?.logo,
                        projectId: organization?.projectId,
                        title: organization?.title,
                        from: 'fetchData',
                    })
                }
                setOptions(data?.data)
            } else {
                setOptions([])
            }
        } catch ({ response }) {
            await setOptions([])
        }
    }, [authToken?.organizationId, selectProject])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const toggleDropdown = (e) => {
        if (e) {
            e.stopPropagation()
            setShowDropdown((prevState) => !prevState)
        }
    }

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(e.target)
            ) {
                toggleDropdown()
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [dropdownRef])

    const optionsItem = options.map((option, index) => {
        return (
            <li
                className={currentIndex === index ? styles.open : ''}
                key={index}
                onClick={() => {
                    selectProject({
                        index,
                        logo: option?.logo,
                        title: option?.title,
                        organizationId: option?.id,
                        projectId: option?.projectId,
                    })
                }}
            >
                <label className={styles.option}>
                    <div className={styles.optionLabel}>
                        <span
                            className={`${styles.icon}
                            ${
                                option?.iconBg
                                    ? option?.iconBg
                                    : 'bg-error-dark'
                            }`}
                        >
                            {option?.logo?.originalURL ? (
                                <Image
                                    alt={option?.title}
                                    width={20}
                                    height={20}
                                    src={option?.logo?.originalURL}
                                />
                            ) : (
                                <span>{option?.title[0]}</span>
                            )}
                        </span>
                        {option.title}
                    </div>
                </label>
            </li>
        )
    })

    return (
        <div className="sidebar-selectbox relative mx-2 mt-4">
            <div
                className={`${styles.select} custom-selectbox`}
                onClick={(e) => {
                    if (scopes.includes('admin')) {
                        toggleDropdown(e)
                    }
                }}
            >
                <div className={styles.selectedOption}>
                    <span
                        className={`${styles.icon} ${
                            selectedOption?.iconBg
                                ? selectedOption?.iconBg
                                : 'bg-error-dark'
                        }`}
                    >
                        {selectedOption?.logo?.originalURL ? (
                            <Image
                                alt={selectedOption?.title}
                                width={20}
                                height={20}
                                src={selectedOption?.logo?.originalURL}
                            />
                        ) : (
                            <span>{selectedOption?.title[0]}</span>
                        )}
                    </span>
                    <span className={styles.label}>
                        {selectedOption?.title}
                    </span>
                </div>
                {scopes.includes('admin') && (
                    <i
                        className={`${styles.dropdownIcon} icon-down-arrow pointer-events-none`}
                    ></i>
                )}
            </div>

            {showDropdown && (
                <ul
                    className={`${styles.dropdown} select-dropdown scrollable max-h-[222px] shadow-lg`}
                    ref={dropdownRef}
                >
                    {optionsItem}
                </ul>
            )}
        </div>
    )
}

export default OrganizationList
