import Image from 'next/image'

import dayjs from '@/helpers/dayjs'
import styles from '@/styles/Auth.module.css'

const AuthLayout = ({ children }) => {
    return (
        <div className="fixed h-screen w-full overflow-hidden md:relative md:h-auto ipad:h-screen">
            <div className="flex bg-interface-50 md:h-screen md:w-full md:flex-col-reverse md:items-center md:justify-end mobile:justify-center">
                <div className={styles.authFormSection}>
                    {/* mobile logo */}
                    <div
                        className={`${styles.authLogo} mb-6 hidden mobile:block`}
                    >
                        <Image
                            src="/logo-inverse.svg"
                            alt="My Image"
                            width={100}
                            height={60}
                        />
                    </div>
                    <div className="mb-6 2xl:min-h-[510px]">{children}</div>
                    <div className="absolute bottom-4 left-0 right-0 px-5 text-center text-interface-600 2xl:relative 2xl:bottom-0">
                        Copyright © {dayjs().year()} - ecOS, Inc · All Rights
                        Reserved.
                    </div>
                </div>
                <div
                    className={`h-screen w-full bg-[#172D54]  text-common-white ${styles.bgImage} md:mt-[-40px] md:h-[400px]  mobile:hidden`}
                >
                    <div className="flex flex-col items-center justify-center py-6 md:h-[100%]">
                        <div className={styles.authLogo}>
                            <Image
                                src="/logo.svg"
                                alt="My Image"
                                width={123}
                                height={60}
                            />
                        </div>
                        <div className="mx-auto max-w-[760px] px-6 text-center">
                            <h2 className="mx-auto mb-3 text-[32px] leading-normal md:max-w-[500px] md:text-[28px]">
                                Energy & Carbon Operation System{' '}
                                {/* <span className="text-secondary-500">
                                    (ECOS)
                                </span> */}
                            </h2>
                            <p className="text-lg">
                                Accelerate to Net Zero with a smart building
                                platform that monitors energy saving investments
                                and keeps you on track.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout
