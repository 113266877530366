import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    users: null,
    keyword: null,
    timezone: undefined,
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload
        },
        setUsers: (state, { payload }) => {
            state.users = payload
        },
        setKeyword: (state, { payload }) => {
            state.keyword = payload
        },
        setTimezone: (state, { payload }) => {
            state.timezone = payload
        },
    },
})

export const { setItem, setUsers, setKeyword, setTimezone } =
    sharedSlice.actions

export default sharedSlice.reducer
