/* eslint-disable max-lines */
import React, { useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Logo from '@/components/base/Logo'
import Button from '@/components/base/Button'
import BaseModal from '@/components/base/Modal'
import Separator from '@/components/base/Separator'
import Navigation from '@/components/base/Navigation'
import ProfileModal from '@/components/modal/ProfileModal'
import Notifications from '@/components/notifications/Notifications'

import { Menu } from '@headlessui/react'
import styles from '@/styles/Header.module.css'
import { useSession } from '@/contexts/Session'

// eslint-disable-next-line max-lines-per-function
const Header = ({ isMenuOpen }) => {
    const { authToken, removeAuthToken, scopes } = useSession()
    const [openModal, setOpenModal] = useState(false)
    const { push } = useRouter()
    const [showNotifcations, setShowNotifications] = useState(false)
    const navigation = [
        // {
        //     count: '9+',
        //     icon: 'icon-notification',
        //     click: (e) => {
        //         e.stopPropagation()
        //         setShowNotifications((prevState) => !prevState)
        //     },
        // },
        // {
        //     route: '/help',
        //     icon: 'icon-help',
        //     isShow: true,
        // },
        // {
        //     route: '/management/organization',
        //     icon: 'icon-cog',
        //     isShow: !!scopes?.find((f) => f === 'management-organization'),
        //     tooltipLabel: 'Organization Manager',
        // },
        {
            icon: 'icon-signout',
            click: () => {
                signOut()
            },
            isShow: true,
            tooltipLabel: 'Logout',
        },
        {
            click: () => {
                setOpenModal(true)
            },
            icon: 'icon-avatar',
            isShow: true,
            tooltipLabel: 'Profile',
        },
    ].filter((f) => f.isShow)
    const notifications = [
        {
            title: 'The CSV report for Load profiles has been downloaded successfully.',
            link: '/site/intervals-baseload',
        },
        {
            title: 'Admin A password updated, please logout and login again using the new password in order to perform all actions',
            link: '/management/admin-accounts',
        },
    ]
    const handleClose = () => {
        setShowNotifications(false)
    }
    const signOut = async () => {
        const signOutURL = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`
        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }
        await fetch(signOutURL, fetchOptions)
        removeAuthToken('accessToken', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('slug', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('organizationId', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('logo', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('projectId', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('title', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        removeAuthToken('isMenuOpen', {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
        push('/')
    }
    return (
        <div className={styles.header}>
            <div className={styles.headerInner}>
                <div>
                    <Logo src="/logo.svg" className="ml-12" />
                </div>
                <div className="header-right-side">
                    <div
                        className={`header-menu menu-wrapper ${
                            isMenuOpen ? 'mobile-nav-active' : ''
                        }`}
                    >
                        <Menu as="div" className={`relative inline-block`}>
                            <Menu.Button>
                                <span className="relative right-3 top-[2px]">
                                    <i className="icon-cog item-icon text-[20px] font-bold text-common-white"></i>
                                </span>
                            </Menu.Button>
                            <Menu.Items className="dropdown-menu link-dropdown-menu right-2 top-[22px] z-50 flex flex-col overflow-visible">
                                <Menu.Item>
                                    {({ close }) => (
                                        <Menu as="div" className="relative">
                                            <Menu.Button
                                                as="div"
                                                className="parent-btn relative w-full pl-0"
                                            >
                                                <Button
                                                    variant="dropdown-menu"
                                                    type="button"
                                                    text="Manage"
                                                    modification="w-full"
                                                />
                                                <span className="absolute right-3 top-[5px]">
                                                    <i className="icon-next text-[10px]" />
                                                </span>
                                            </Menu.Button>
                                            <Menu.Items
                                                className={`dropdown-menu link-dropdown-menu header-dropdown-menu`}
                                            >
                                                <Menu.Item>
                                                    <Button
                                                        variant="dropdown-menu"
                                                        type="button"
                                                        text="Organization"
                                                        link={
                                                            scopes.includes(
                                                                'admin'
                                                            )
                                                                ? '/management/organization'
                                                                : scopes.includes(
                                                                      'member'
                                                                  )
                                                                ? `/management/organization/meters/${authToken.organizationId}`
                                                                : `/management/organization/setup/${authToken.organizationId}`
                                                        }
                                                        modification="w-full !pl-4"
                                                        click={() => {
                                                            close()
                                                        }}
                                                    />
                                                </Menu.Item>
                                                {!scopes.includes('member') && (
                                                    <>
                                                        <Separator modification="bg-interface-200 !my-[3px]" />
                                                        <Menu.Item>
                                                            <Button
                                                                variant="dropdown-menu"
                                                                type="button"
                                                                text="Users"
                                                                link="/organization/user-accounts"
                                                                modification="w-full !pl-4"
                                                                click={() => {
                                                                    close()
                                                                }}
                                                            />
                                                        </Menu.Item>
                                                    </>
                                                )}
                                            </Menu.Items>
                                        </Menu>
                                    )}
                                </Menu.Item>
                                <Separator modification="bg-interface-200 !my-[4px]" />
                                <Menu.Item>
                                    {({ close }) => (
                                        <Menu as="div" className="relative">
                                            <Menu.Button
                                                as="div"
                                                className="parent-btn relative w-full pl-0"
                                            >
                                                <Button
                                                    variant="dropdown-menu"
                                                    type="button"
                                                    text="Tools"
                                                    modification="w-full"
                                                />
                                                <span className="absolute right-3 top-[5px]">
                                                    <i className="icon-next text-[10px]" />
                                                </span>
                                            </Menu.Button>
                                            <Menu.Items
                                                className={`dropdown-menu link-dropdown-menu header-dropdown-menu`}
                                            >
                                                <Menu.Item>
                                                    <Button
                                                        variant="dropdown-menu"
                                                        type="button"
                                                        text="Activity Log"
                                                        click={() => {
                                                            close()
                                                        }}
                                                        link="/activity-log"
                                                        modification="w-full !pl-4"
                                                    />
                                                </Menu.Item>
                                                {/* <Separator modification="bg-interface-200 !my-[3px]" />
                                                <Menu.Item>
                                                    <Button
                                                        variant="dropdown-menu"
                                                        type="button"
                                                        text="Export Data"
                                                        click={() => {
                                                            close()
                                                        }}
                                                        link="/export-data"
                                                        modification="w-full !pl-4"
                                                    />
                                                </Menu.Item> */}
                                                <Separator modification="bg-interface-200 !my-[3px]" />
                                                <Menu.Item>
                                                    <Button
                                                        variant="dropdown-menu"
                                                        type="button"
                                                        text="Rules"
                                                        click={() => {
                                                            close()
                                                        }}
                                                        link="/rules"
                                                        modification="w-full !pl-4"
                                                    />
                                                </Menu.Item>
                                                {/* <Separator modification="bg-interface-200 !my-[3px]" />
                                                <Menu.Item>
                                                    <Button
                                                        variant="dropdown-menu"
                                                        type="button"
                                                        text="Tags"
                                                        click={() => {
                                                            close()
                                                        }}
                                                        link="/tags"
                                                        modification="w-full !pl-4"
                                                    />
                                                </Menu.Item> */}
                                            </Menu.Items>
                                        </Menu>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                        <Navigation
                            items={navigation}
                            customClass="header-nav"
                        />
                        {showNotifcations && (
                            <Notifications
                                notifications={notifications}
                                handleClose={handleClose}
                            />
                        )}
                        <div className="company-logo">
                            <Image
                                alt={authToken?.title}
                                width={99}
                                height={33}
                                className="h-[33px] w-auto"
                                src={
                                    authToken?.logo?.thumbnailURL ||
                                    authToken?.logo?.originalURL
                                        ? authToken?.logo?.thumbnailURL ||
                                          authToken?.logo?.originalURL
                                        : '/dummy/organizations/logo-dummy.svg'
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <BaseModal
                ModalBody={ProfileModal}
                isModalOpen={openModal}
                isModalClose={() => setOpenModal(false)}
                title="Profile"
                props={{ setOpenModal }}
            />
        </div>
    )
}
export default Header
