import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import calendar from 'dayjs/plugin/calendar'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)

export const calendarDate = (date) => {
    return dayjs(date).calendar(undefined, {
        sameDay: '[Today,] MMM DD, YYYY [at] h:mm A',
        nextDay: '[Tomorrow,] MMM DD, YYYY [at] h:mm A',
        lastDay: '[Yesterday,] MMM DD, YYYY [at] h:mm A',
        lastWeek: 'dddd[,] MMM DD, YYYY [at] h:mm A',
        sameElse: 'dddd[,] MMM DD, YYYY [at] h:mm A',
    })
}

export default dayjs
