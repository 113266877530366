import React from 'react'

const SeparatorWithTitle = ({ modification, title, icon, variant }) => {
    return (
        <div className={`separator-with-title w-full text-center  ${variant}`}>
            <span className={`${modification} title-wrapper`}>
                {icon && <i className={`mr-1 ${icon} lock-icon`}></i>}
                <span className="title">{title}</span>
            </span>
        </div>
    )
}

export default SeparatorWithTitle
