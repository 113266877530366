import React from 'react'
import Link from 'next/link'
import styles from '@/styles/Notifications.module.css'

function NotificationItem({ title, link, handleClose }) {
    return (
        <div className={`${styles.notificationItem}`}>
            <p className={`${styles.title}`}>{title}</p>
            <Link href={link} className={`${styles.link}`}>
                <span onClick={handleClose}>View</span>
            </Link>
        </div>
    )
}

export default NotificationItem
