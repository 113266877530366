import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Auth = ({ children }) => {
    const { push, asPath, pathname } = useRouter()
    const { isAuthenticated, setNextURL, scopes } = useSession({
        required: true,
        onUnauthenticated() {
            setNextURL(asPath)
            if (asPath !== '/') {
                push('/')
            }
        },
        onAuthenticated() {
            const hasScope = scopes.includes(
                pathname.slice(1).replaceAll(/\//g, '-')
            )
            if (scopes.length && !hasScope && asPath !== '/eco-pilot') {
                push('/eco-pilot')
            }
        },
    })

    if (isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Auth
