import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress' // nprogress module
import 'nprogress/nprogress.css' // styles of nprogress

import { Cookies, CookiesProvider } from 'react-cookie'

import { Provider } from 'react-redux'
import { store } from '@/redux/store'

import Auth from '@/middlewares/Auth'
import Guest from '@/middlewares/Guest'

import { SessionProvider } from '@/contexts/Session'

// Layout
import MainLayout from '@/layouts/Main'
import AuthLayout from '@/layouts/Auth'
import ErrorLayout from '@/layouts/Error'

import '@/styles/fonts.css'
import '@/styles/tailwind.css'
import '@/styles/icons.css'
import '@/styles/redactedfont.css'

// Binding events
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps, layoutProps, cookies }) => {
    // Use the layout defined at the page level, if available
    const isBrowser = typeof window !== 'undefined'

    return (
        <CookiesProvider cookies={isBrowser ? undefined : new Cookies(cookies)}>
            <SessionProvider>
                <Provider store={store}>
                    <>
                        {/* Default Layout */}
                        {Component.auth &&
                            !Component.guest &&
                            !Component.error && (
                                <Auth>
                                    <MainLayout>
                                        <Component {...pageProps} />
                                    </MainLayout>
                                </Auth>
                            )}

                        {/* Auth Layout */}
                        {Component.guest &&
                            !Component.auth &&
                            !Component.error && (
                                <Guest>
                                    <AuthLayout {...layoutProps}>
                                        <Component {...pageProps} />
                                    </AuthLayout>
                                </Guest>
                            )}

                        {/* Error Layout */}
                        {Component.error && (
                            <ErrorLayout>
                                <Component {...pageProps} />
                            </ErrorLayout>
                        )}
                    </>
                </Provider>
            </SessionProvider>
        </CookiesProvider>
    )
}

MyApp.getInitialProps = async (appContext) => {
    const appProps = await App.getInitialProps(appContext)
    return {
        ...appProps,
        cookies: appContext.ctx.req?.headers?.cookie,
    }
}

export default MyApp
