const Separator = (props) => {
    return (
        <>
            <div
                className={`${props.modification} ${
                    props.noSpace ? '' : 'mb-6'
                } h-[1px] w-full bg-interface-100`}
            ></div>
        </>
    )
}

export default Separator
