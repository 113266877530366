import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Guest = ({ children }) => {
    const { push, asPath } = useRouter()
    const { isAuthenticated, nextURL } = useSession({
        required: false,
        onAuthenticated() {
            if ((nextURL ? nextURL : '/eco-pilot') !== asPath) {
                push(nextURL ? nextURL : '/eco-pilot')
            }
        },
    })

    if (!isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Guest
