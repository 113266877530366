import map from 'lodash/map'
import Link from 'next/link'
import { useRouter } from 'next/router'
import SeparatorWithTitle from '@/components/base/SeparatorWithTitle'

import styles from '@/styles/Sidebar.module.css'

const SidebarNavigation = (props) => {
    const { asPath, pathname } = useRouter()

    return (
        <>
            <div className={`${styles.sidebarMenuList}`}>
                <ul className={`${styles.menuItem} menu-item`}>
                    {map(props.menuItems, (item, index) => (
                        <li
                            key={index}
                            className={
                                asPath.startsWith(item.path)
                                    ? styles.active
                                    : ''
                            }
                        >
                            <Link href={item.path}>
                                <span onClick={() => props.handleClick(index)}>
                                    <i className={item.icon} />
                                    {item.title}
                                </span>
                            </Link>
                            {item.subMenuItems?.length > 0 && (
                                <>
                                    <i
                                        className={`icon-down-arrow ${styles.dropdown}`}
                                    />
                                    {props.activeMenu === index && (
                                        <ul className={styles.subMenu}>
                                            {map(
                                                item.subMenuItems,
                                                (subMenuItem, subMenuIndex) => (
                                                    <li
                                                        key={`${subMenuItem}-${subMenuIndex}`}
                                                        className={`${
                                                            pathname ===
                                                                subMenuItem.pathname ||
                                                            subMenuItem?.children?.includes(
                                                                pathname
                                                            )
                                                                ? styles.active
                                                                : ''
                                                        }`}
                                                    >
                                                        <Link
                                                            href={
                                                                subMenuItem.path
                                                            }
                                                        >
                                                            <span>
                                                                {
                                                                    subMenuItem.label
                                                                }
                                                            </span>
                                                        </Link>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </>
                            )}
                            {item.showSeparator && (
                                <div className="pb-2 pt-1">
                                    <SeparatorWithTitle
                                        variant={`before:bg-primary-700 w-[100%] ml-[-8px] mr-[-8px] `}
                                        modification="hidden"
                                    />
                                </div>
                            )}
                            {item.showManagementSeparator && (
                                <div className="mt-1">
                                    <SeparatorWithTitle
                                        variant="before:bg-primary-700 w-[100%] ml-[-8px] mr-[-8px]"
                                        modification="!bg-primary-700 p-1.5 rounded-lg top-1 text-xs !text-primary-100"
                                        title="Management"
                                        icon="icon-icon-lock"
                                    />
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default SidebarNavigation
