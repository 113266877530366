import React, { useEffect, useRef } from 'react'
import NoNotificationFound from './NoNotificationFound'
import NotificationItem from '@/components/notifications/NotificationItem'
import styles from '@/styles/Notifications.module.css'

function Notifications({ notifications, handleClose }) {
    const notificationRef = useRef()

    // outside click handler
    useEffect(() => {
        const handleClickOutside = (e) => {
            notificationRef.current &&
                !notificationRef.current.contains(e.target) &&
                handleClose()
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [notificationRef, handleClose])

    return (
        <div ref={notificationRef} className={`${styles.notifications}`}>
            <div className={`${styles.header}`}>
                <p className="text-base font-semibold text-common-white">
                    Notifications
                </p>
                <div className={`${styles.close}`} onClick={handleClose}>
                    <i className="icon-cross text-sm text-interface-400"></i>
                </div>
            </div>

            {notifications.length > 0 && (
                <ul className={`${styles.notitificationList} scrollable`}>
                    {notifications.map((item, index) => {
                        return (
                            <NotificationItem
                                key={index}
                                title={item.title}
                                link={item.link}
                                handleClose={handleClose}
                            />
                        )
                    })}
                </ul>
            )}

            {notifications.length === 0 && <NoNotificationFound />}
        </div>
    )
}

export default Notifications
