import axios from '@/helpers/axios'
import startCase from 'lodash/startCase'

export const trackViews = async (pageURL, projectId) => {
    if (pageURL) {
        try {
            /* Views Log */
            await axios.post(`views`, {
                pageTitle: capitalizeFirstLetter(pageURL),
                pageURL: getUrlFromString(pageURL),
                projectId,
            })
        } catch (error) {
            // Handle the error appropriately
            // eslint-disable-next-line
            console.log('Error recording views:', error)
        }
    }
}

function getUrlFromString(str) {
    return str?.replace(/^\//, '')?.split('?')?.[0]
}

function capitalizeFirstLetter(str) {
    str = getUrlFromString(str)
    const words = str.split('/')
    const joinWords = []
    for (let i = 0; i < words.length; i++) {
        const isNumber = parseInt(words[i])
        if (isNaN(isNumber)) {
            joinWords.push(startCase(words[i]))
        }
    }

    return joinWords.join(' | ')
}
