import React, { useMemo, useState, useCallback } from 'react'
import { Formik, Form } from 'formik'

import Alert from '@/components/base/Alert'
import Button from '@/components/base/Button'
import TextBox from '@/components/base/TextBox'
import SeparatorWithTitle from '@/components/base/SeparatorWithTitle'

import { useSession } from '@/contexts/Session'

import profileValidation from '@/validations/profile'

import axios from '@/helpers/axios'

const ProfileModal = ({ setOpenModal }) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)

    const { user, setUser } = useSession()

    const initialState = useMemo(
        () => ({
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            password: '',
            newPassword: '',
            confirmPassword: '',
        }),
        [user]
    )

    const handleSubmit = useCallback(
        async (formData) => {
            setErrorMessage(null)
            setLoading(true)

            try {
                const { data } = await axios.put(`user/me`, formData)
                if (data) {
                    const { message } = data
                    await setUser({
                        ...user,
                        fullName: `${formData.firstName} ${formData.lastName}`,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                    })
                    setSuccessMessage(message)
                    const timer = setTimeout(() => {
                        setSuccessMessage(null)
                        setLoading(false)
                        setOpenModal(false)
                    }, 3000)
                    return () => clearTimeout(timer)
                }
            } catch ({ response }) {
                const message = response.data.message
                setErrorMessage(message)
            }
            setLoading(false)
        },
        [setUser, user, setOpenModal]
    )
    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialState}
                validationSchema={profileValidation}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    const message = errors[firstKey] || errorMessage
                    return (
                        <Form noValidate className="lg:w-full">
                            <div className="grid gap-x-4">
                                <TextBox
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    label="First name"
                                    placeholder="Enter first name"
                                    isRequired
                                />
                                <TextBox
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    label="Last name"
                                    placeholder="Enter last name"
                                    isRequired
                                />
                                <TextBox
                                    id="email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    placeholder="Enter email address"
                                    disabled={true}
                                />
                                <div className="col-span-2 mt-2">
                                    <SeparatorWithTitle
                                        title="Change Password"
                                        modification="rounded-md p-1 mb-2"
                                    />
                                </div>
                                <TextBox
                                    id="password"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Enter password"
                                />
                                <TextBox
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    label="New Password"
                                    placeholder="Enter password"
                                />
                                <TextBox
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    label="Confirm Password"
                                    placeholder="Enter password"
                                />

                                <div className="col-span-2 w-full">
                                    <div className="mt-4">
                                        {(showError || errorMessage) && (
                                            <Alert
                                                variant="danger"
                                                message={message}
                                                icon="cancel-fill"
                                                iconStyle="text-danger-500 relative top-[1px] pr-1"
                                            ></Alert>
                                        )}

                                        {successMessage && (
                                            <Alert
                                                variant="success"
                                                message={successMessage}
                                                icon="check-fill"
                                                iconStyle="text-success-500 relative top-[1px] pr-1"
                                            ></Alert>
                                        )}
                                    </div>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        text="Submit"
                                        modification="mb-3 ipad:mb-0 w-full"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ProfileModal
