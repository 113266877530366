import styles from '@/styles/Button.module.css'
const Loader = (props) => {
    return (
        <div
            className={`${
                props.loaderContainer ? props.loaderContainer : 'py-10'
            }`}
        >
            <div
                className={` ${props.className} ${
                    props.dataLoader
                        ? 'data-loader flex items-center justify-center '
                        : ''
                }`}
            >
                <span className={styles.spinner}>
                    <div
                        className={`${styles.spinnerInner} ${
                            props.chatLoading ? styles.chatLoading : ''
                        }`}
                    ></div>
                </span>
                {props.text && (
                    <span className={styles.loaderMsg}>{props.text}</span>
                )}
            </div>
            {props.text2 && (
                <p className="mb-0 text-center text-base text-interface-600">
                    {props.text2}
                </p>
            )}
        </div>
    )
}

export default Loader
