import React, { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import styles from '../../styles/Alert.module.css'

const Alert = ({
    variant,
    message,
    setMessage,
    size,
    icon,
    iconStyle,
    noSpace,
}) => {
    const alertRef = useRef(null)
    const { validateOnChange } = useFormikContext() || {}

    useEffect(() => {
        if (!validateOnChange)
            alertRef.current.scrollIntoView({
                behavior: 'smooth',
            })
    }, [alertRef, validateOnChange])

    useEffect(() => {
        let timer
        if (message && setMessage) {
            timer = setTimeout(() => {
                setMessage(null)
            }, 2000)
        }

        return () => {
            if (timer) {
                clearInterval(timer)
            }
        }
    }, [message, setMessage])

    return (
        <div
            ref={alertRef}
            className={`${styles.alert} ${styles[variant]} ${styles[size]} ${
                noSpace ? 'mb-0' : 'mb-5'
            }`}
        >
            {icon && (
                <span>
                    {icon && (
                        <i className={`${`icon-` + icon}  ${iconStyle}`}></i>
                    )}
                </span>
            )}
            <span>{message}</span>
        </div>
    )
}

export default Alert
