import * as Yup from 'yup'
import { profile } from './messages'

export default Yup.object().shape({
    firstName: Yup.string().required(profile.firstName.required),
    lastName: Yup.string().required(profile.lastName.required),

    email: Yup.string()
        .required(profile.email.required)
        .email(profile.email.invalid),

    password: Yup.string()
        .trim()
        .required(profile.password.required)
        .min(8, profile.password.min)
        .max(20, profile.password.min),

    newPassword: Yup.string()
        .nullable()
        .min(8, profile.password.min)
        .max(20, profile.password.min)
        .test(
            'containsCapitalAndNumber', // type of the validator (should be unique)
            'New password must contain at least one capital letter (A-Z) and one number (0-9).',
            (value) => {
                const containsCapital = /[A-Z]/.test(value)
                const containsNumber = /[0-9]/.test(value)
                return value ? containsCapital && containsNumber : true
            }
        ),

    confirmPassword: Yup.string()
        .nullable()
        .when('newPassword', {
            is: (newPassword) => newPassword,
            then: Yup.string()
                .required(profile.confirmPassword.required)
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    profile.newPassword.mismatch
                ),
        }),
})
