import React from 'react'

function NoNotificationFound() {
    return (
        <div className="py-4 text-center">
            <i className="icon-no-notifications text-[2rem] text-interface-400"></i>
            <p className="text-xs text-interface-400">
                You have no notifications!
            </p>
        </div>
    )
}

export default NoNotificationFound
