import { useFormikContext } from 'formik'
import { useMemo, useEffect, useCallback } from 'react'

import kebabCase from 'lodash/kebabCase'

import styles from '../../styles/Input.module.css'

const TextBox = ({
    id,
    name,
    placeholder,
    label,
    type = 'text',
    ariaLabel,
    variant,
    defaultValue = '',
    disabled = false,
    noSpace = false,
    size,
    customCssContainer,
    customcss,
    cardinput,
    iconClass,
    withoutBorder = false,
    inputModify,
    iconModifier,
    isRequired,
    onBlur,
    fieldText,
    fieldTextStyle,
}) => {
    const { handleBlur, handleChange, getFieldMeta } = useFormikContext() || {}
    const { value, error, touched } = getFieldMeta ? getFieldMeta(name) : {}
    const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

    defaultValue = value === '' || value === null ? '' : value

    const handleKeyDown = useCallback(
        (event) => {
            if (
                type === 'number' &&
                (event.key === 'ArrowUp' || event.key === 'ArrowDown')
            ) {
                event.preventDefault()
            }
        },
        [type]
    )

    const handleScroll = useCallback(
        (event) => {
            if (type === 'number' && document.activeElement === event.target) {
                event.preventDefault()
            }
        },
        [type]
    )

    useEffect(() => {
        const inputElement = document.getElementById(id$)

        if (inputElement && type === 'number') {
            inputElement.addEventListener('wheel', handleScroll, {
                passive: false,
            })
        }

        return () => {
            if (inputElement && type === 'number') {
                inputElement.removeEventListener('wheel', handleScroll)
            }
        }
    }, [id$, type, handleScroll])

    return (
        <div
            className={`${styles.inputModule} ${customCssContainer} ${
                cardinput ? styles.cardInterface : ''
            } `}
        >
            <div
                className={`${styles.formGroup} ${
                    noSpace ? styles.noSpace : ''
                }`}
            >
                {label && (
                    <label
                        htmlFor={id$}
                        className={`${styles.inputLabel} ${
                            isRequired ? styles.withAstrik : ''
                        }`}
                    >
                        {label}
                        {isRequired && <span className={styles.astrik}>*</span>}
                    </label>
                )}

                <div className="relative">
                    <input
                        id={id$}
                        name={name}
                        type={type}
                        disabled={disabled}
                        className={`${styles.formControl} ${styles[variant]} ${
                            styles[size]
                        } ${
                            error && touched ? styles.hasError : ''
                        } ${customcss} ${
                            withoutBorder ? styles.withoutBorder : ''
                        } ${inputModify}`}
                        aria-label={ariaLabel ? ariaLabel : label}
                        placeholder={placeholder}
                        onBlur={(event) => {
                            handleBlur(event)
                            onBlur && onBlur(event)
                        }}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={value || defaultValue}
                    />
                    {fieldText && (
                        <span
                            className={`${fieldTextStyle} ${styles.fieldText}`}
                        >
                            {fieldText}
                        </span>
                    )}

                    {iconClass && (
                        <i
                            className={`${iconClass} ${styles.icon} ${iconModifier}`}
                        ></i>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TextBox
